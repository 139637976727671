<template>
  <div class="w-100" v-if="fixture">
    <div class="title-actions">
      <h5 class="title">{{$t('editMatch')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-6 text-right">
        <h4 :class="fixture.local_css.name">
          <small class="mr-2">{{ fixture.team_local_name }}</small>
          <span class="badge badge-pill" :class="fixture.local_css.goals">{{ fixture.local_goals }}</span>
        </h4>
      </div>
      <div class="col-6">
        <h4 :class="fixture.visit_css.name">
          <span class="badge badge-pill" :class="fixture.visit_css.goals">{{ fixture.visit_goals }}</span>
          <small class="ml-2">{{ fixture.team_visit_name }}</small>
        </h4>
      </div>
      <div class="col-12 text-center">
        <hr />
        <b-button
          class="mx-1"
          variant="success"
          @click="openModalChangeResults"
        >{{ $t('changeResults') }}</b-button>
        <b-button
          class="mx-1"
          variant="success"
          @click="openModalEnabledStatistics"
        >{{ $t('changeStatistics') }}</b-button>
        <hr />
      </div>
      <div class="col-6 text-center">
        <router-link
          class="mx-3 text-uppercase"
          :to="{ name: 'TeamBackResultsEditMatchStatistics', params: { lang: lang, console: console, fixtureId: fixtureId, teamId: fixture.team_local } }"
        >{{ $t('changeResults') }} {{ fixture.team_local_name }}</router-link>
      </div>
      <div class="col-6 text-center">
        <router-link
          class="mx-3 text-uppercase"
          :to="{ name: 'TeamBackResultsEditMatchStatistics', params: { lang: lang, console: console, fixtureId: fixtureId, teamId: fixture.team_visit } }"
        >{{ $t('changeResults') }} {{ fixture.team_visit_name }}</router-link>
      </div>
    </div>
    <modal-change-results :fixture="fixtureCopy" @updateResults="updateResults" />
    <modal-enabled-statistics :fixture="fixtureCopy" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ModalChangeResults from "../partials/ModalChangeResults";
import ModalEnabledStatistics from "../partials/ModalEnabledStatistic";

export default {
  components: {
    ModalChangeResults,
    ModalEnabledStatistics
  },
  data() {
    return {
      fixtureId: this.$route.params.fixtureId,
      fixture: null,
      fixtureCopy: {}
    };
  },
  computed: {
    ...mapGetters(["lang", "user", "console"])
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    openModalEnabledStatistics() {
      this.fixtureCopy = JSON.parse(JSON.stringify(this.fixture));
      this.$root.$emit("bv::show::modal", "modalEnabledStatistics");
    },
    openModalChangeResults() {
      this.fixtureCopy = JSON.parse(JSON.stringify(this.fixture));
      this.$root.$emit("bv::show::modal", "modalChangeResults");
    },
    updateResults(params) {
      this.fixture.local_goals = params.local_goals;
      this.fixture.visit_goals = params.visit_goals;
    },
    fetchData() {
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/edit`;
      this.$axios
        .get(path)
        .then(response => {
          this.fixture = response.data.data;
          if (this.fixture.team_local !== this.fixture.id_winner) {
            this.fixture.local_css = {
              name: "text-danger",
              goals: "badge-danger"
            };
          } else {
            this.fixture.local_css = {
              name: "text-info",
              goals: "badge-info"
            };
          }
          if (this.fixture.team_visit !== this.fixture.id_winner) {
            this.fixture.visit_css = {
              name: "text-danger",
              goals: "badge-danger"
            };
          } else {
            this.fixture.visit_css = {
              name: "text-info",
              goals: "badge-info"
            };
          }
        })
        .catch(() => {
          this.$router.go(-1);
        });
    }
  }
};
</script>
